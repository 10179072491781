import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
      <Route path={`${match.url}c2b`} component={asyncComponent(() => import('./Transactions/c2b'))} />
      <Route path={`${match.url}ussd`} component={asyncComponent(() => import('./Transactions/ussd'))} />
      <Redirect to="/dashboard" />
    </Switch>
  </div>
);

export default App;
