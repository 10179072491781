import axios from "axios";

const axios_ = axios.create({
  // Production
  // baseURL: `https://155.12.30.122:8893/epg/`,
  // baseURL: `http://155.12.30.122:8085/epg`,
  // baseURL: `http://192.168.145.2:8893/epg`
  baseURL: `https://reconportal.ewallet-dev.com/epg/services/v1/`,

});

axios_.interceptors.request.use((config) => {


  const token = localStorage.getItem("token")
  config.params = config.params || {};
  config.headers['Content-type'] = `application/json`
  // config.headers['Authorization'] = `Bearer ${token}`
  return config;
});

export default axios_;
